import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import styles from './UserFormStyles';
import Button from '../../../Venti-UI-Kit/Buttons/Button';
import { useFormDeprecated } from '../../../hooks/useFormDeprecated';
import PersonalDataForm from './PersonalDataForm/PersonalDataForm';
import ProducersListForm from './ProducersListForm/ProducersListForm';
import { BLACKLISTABLE_ROLES } from '../../../constants/constants';
import api from '../../../api/api';
import { NOTIFICATION_VARIANT, USER_PERMISSIONS, USER_ROLES } from '../../../constants/types';
import permissions from '../../../Providers/Permissions/Permissions';
import ResetPasswordCard from './ResetPasswordCard/ResetPasswordCard';
import { useUser } from '../../../hooks/useUser';
import ConfirmModal from '../../../Venti-UI-Kit/Modals/ConfirmModal';
import { translate } from '../../../utils/translate';
import { handleRequestHelper } from '../../../utils/helpers';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import history from '../../../appHistory';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { validateUserRole } from '../../../utils/utils';

const NO_TEAM_LEADER_ID = 0;

const UserForm = ({ classes, producerId, edit = false, initialData }) => {
  const { user } = useAuth();
  const { showNotification } = useNotifications();
  const [initialUserData, setInitialUserData] = useState(initialData);
  const [formErrors, setFormErrors] = useState({});
  const [roleIsBlacklistable, setRoleIsBlacklistable] = useState(false);
  const [producersListFormState, setProducersListFormState] = useState();
  const [disabledStates, setDisabledStates] = useState({
    producerList: true,
    submitButton: true,
  });
  const [userAlreadyExists, setUserAlreadyExists] = useState(edit);
  const [userIsCustomer, setUserIsCustomer] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [creatingOrUpdatingUser, setCreatingOrUpdatingUser] = useState(false);
  const enableSuperAdminEdition = validateUserRole(user.role, USER_PERMISSIONS.SUPER_ADMIN);

  const handleOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  useEffect(() => {
    setInitialUserData(initialData);
  }, [initialData]);

  const { initialUserInfo } = initialUserData ?? {};
  const { mail, alias, role, id } = initialUserInfo ?? {};
  const {
    formState,
    handleInputChange,
    isFormComplete,
    validateMail,
    formHasErrors,
    validateAlias,
    validateNewPassword,
    validateRequiredField,
    filterUnusedFields,
    setFormState,
  } = useFormDeprecated(
    {
      mail: mail || '',
      alias: alias || '',
      role: role || '',
      password: '',
    },
    ['password']
  );

  const { getUserDataFormatted, getUserEventsRestrictedAccesses } = useUser();

  const validateForm = () => {
    const { mail, alias, role, password } = formState;
    if (!mail) return;

    const errors = {
      mail: validateMail(mail),
      alias: validateAlias(alias),
      role: validateRequiredField(role),
      password: password ? validateNewPassword(password, mail) : '',
    };
    setFormErrors(errors);
    return !formHasErrors(errors);
  };

  const isBlacklistableRole = () => {
    return BLACKLISTABLE_ROLES.includes(formState.role);
  };

  useEffect(() => {
    if (isFormComplete(formState)) setDisabledStates({ submitButton: false, producerList: false });
    setRoleIsBlacklistable(isBlacklistableRole());
  }, [formState]);

  const getExcludedEvents = (eventsAccessStates) => {
    const { allEvents, ...eventsAccessStatesWithoutAllEventsState } = eventsAccessStates;
    return Object.entries(eventsAccessStatesWithoutAllEventsState).reduce(
      (eventsIds, [eventId, hasAccess]) =>
        hasAccess ? [...eventsIds, parseInt(eventId)] : eventsIds,
      []
    );
  };

  const formatUserProducers = () => {
    const { selectedProducers, selectedProducersEvents } = producersListFormState;
    return Object.entries(selectedProducers).reduce((producers, [producerId, hasAccess]) => {
      if (!hasAccess) return producers;

      const producer = {
        id: parseInt(producerId),
        ...(isBlacklistableRole() && {
          hasRestrictedAccessToEvents: !selectedProducersEvents[producerId].allEvents,
          ...(!selectedProducersEvents[producerId].allEvents && {
            eventsExcluded: getExcludedEvents(selectedProducersEvents[producerId]),
          }),
        }),
      };

      return [...producers, producer];
    }, []);
  };

  const deleteOrUpdateUserTeamLeaders = async (userId) => {
    const { selectedProducers, selectedTeamLeaders } = producersListFormState;

    for (const producerId of Object.keys(selectedProducers)) {
      if (!selectedTeamLeaders[producerId]) continue;

      const { teamLeaderId, initialTeamLeaderId } = selectedTeamLeaders[producerId];
      const teamLeaderWasModified = teamLeaderId !== initialTeamLeaderId;

      if (teamLeaderWasModified) {
        if (teamLeaderId === NO_TEAM_LEADER_ID) {
          await handleRequestHelper({
            endpoint: () => api.deleteUserTeamLeader(initialTeamLeaderId, userId),
            onFailure: () => {
              throw new Error('Error al modificar los team leaders');
            },
          });
        } else if (initialTeamLeaderId !== NO_TEAM_LEADER_ID)
          await handleRequestHelper({
            endpoint: () => api.updateTeamLeaderToUser(userId, { teamLeaderId }),
            onFailure: () => {
              throw new Error('Error al modificar los team leaders');
            },
          });
      }
    }
  };

  const createUserTeamLeaders = async (userId) => {
    const { selectedProducers, selectedTeamLeaders } = producersListFormState;

    for (const producerId of Object.keys(selectedProducers)) {
      if (!selectedTeamLeaders[producerId]) continue;

      const { teamLeaderId, initialTeamLeaderId } = selectedTeamLeaders[producerId];
      const teamLeaderWasModified = teamLeaderId !== initialTeamLeaderId;

      if (teamLeaderWasModified && initialTeamLeaderId === NO_TEAM_LEADER_ID) {
        await handleRequestHelper({
          endpoint: () => api.addTeamLeaderToUser(teamLeaderId, { userId }),
          onFailure: () => {
            throw new Error('Error al agregar los team leaders');
          },
        });
      }
    }
  };

  const createNewUser = async () => {
    const producers = formatUserProducers();

    if (validateForm()) {
      try {
        let userCreated;
        await handleRequestHelper({
          endpoint: () => api.createUser({ ...formState, producers }),
          onFailure: ({ errorMessage }) => {
            throw new Error(errorMessage);
          },
          onSuccess: ({ user }) => {
            userCreated = user;
          },
        });

        await createUserTeamLeaders(userCreated.id);

        showNotification('El usuario fue creado correctamente', NOTIFICATION_VARIANT.SUCCESS);
        setDisabledStates((prevState) => ({ ...prevState, submitButton: true }));
        setTimeout(() => {
          history.go(0);
        }, 2000);
      } catch (error) {
        showNotification(error.message, NOTIFICATION_VARIANT.ERROR);
      }
    }
  };

  const updateUser = async () => {
    const producers = formatUserProducers();

    if (enableSuperAdminEdition && !validateForm()) return;

    try {
      await handleRequestHelper({
        endpoint: () => api.checkIfAliasExists(formState.alias, id),
        onFailure: ({ errorMessage }) => {
          throw new Error(errorMessage);
        },
      });

      await deleteOrUpdateUserTeamLeaders(id);
      const userData = filterUnusedFields(formState);

      await handleRequestHelper({
        endpoint: () => api.updateUserProducers({ producers, id }),
        onFailure: () => {
          throw new Error('Error al modificar las productoras del usuario');
        },
      });

      if (enableSuperAdminEdition || userIsCustomer) {
        await handleRequestHelper({
          endpoint: () => api.updateUser({ ...userData, id }),
          onFailure: ({ errorMessage }) => {
            throw new Error(errorMessage);
          },
        });
      }

      await createUserTeamLeaders(id);

      const successMessage = edit
        ? 'El usuario fue actualizado correctamente'
        : 'El usuario fue creado correctamente';
      showNotification(successMessage, NOTIFICATION_VARIANT.SUCCESS);
      setDisabledStates((prevState) => ({ ...prevState, submitButton: true }));

      setTimeout(() => {
        history.go(edit ? -1 : 0);
      }, 2000);
    } catch (error) {
      showNotification(error.message, NOTIFICATION_VARIANT.ERROR);
    }
  };

  const handleSubmit = async () => {
    setCreatingOrUpdatingUser(true);
    if (edit || userAlreadyExists) await updateUser();
    else await createNewUser();
    setCreatingOrUpdatingUser(false);
    handleCloseConfirmModal();
  };

  const handleExistingUser = async (user) => {
    const userBelongsToProducer = user.producers.some(({ id }) => parseInt(producerId) === id);

    if (userBelongsToProducer) {
      setFormErrors((prevState) => ({
        ...prevState,
        mail: (
          <>
            Ya existe un usuario con este mail.{' '}
            <a href={`/backoffice/productoras/${producerId}/usuarios/${user.id}`}>Hacé click</a>{' '}
            para ir a modificar sus permisos.
          </>
        ),
      }));
    } else {
      const { initialUserInfo, initialProducers, initialTeamLeaders } = getUserDataFormatted(user);
      const isCustomer = user.role === USER_ROLES.CUSTOMER;
      initialUserInfo.role = isCustomer ? '' : initialUserInfo.role;

      const initialEventsAccesses = await getUserEventsRestrictedAccesses(user.id);
      setInitialUserData((prevState) => ({
        ...prevState,
        initialUserInfo,
        initialProducers: { [producerId]: true, ...initialProducers },
        initialTeamLeaders,
        initialEventsAccesses: { ...prevState.initialEventsAccesses, ...initialEventsAccesses },
      }));
      setFormState(initialUserInfo);
      setUserIsCustomer(isCustomer);
    }
  };

  return (
    <>
      <PersonalDataForm
        formState={formState}
        handleInputChange={handleInputChange}
        formErrors={formErrors}
        setFormState={setFormState}
        setFormErrors={setFormErrors}
        edit={edit}
        handleExistingUser={handleExistingUser}
        userAlreadyExists={userAlreadyExists}
        setUserAlreadyExists={setUserAlreadyExists}
        userIsCustomer={userIsCustomer}
      />
      {edit && <ResetPasswordCard userMail={mail} />}
      <ProducersListForm
        currentProducerId={producerId}
        disabled={disabledStates.producerList}
        roleIsBlacklisteable={roleIsBlacklistable}
        initialUserData={initialUserData}
        roleCanHaveTeamLeader={
          permissions.isSeller(formState.role) || permissions.isSellerWithCash(formState.role)
        }
        onChange={setProducersListFormState}
        handleExistingUser={handleExistingUser}
      />
      <Box className={classes.floatingButtonContainer}>
        <Button
          className={classes.submitButton}
          color="main"
          onClick={!edit ? handleOpenConfirmModal : handleSubmit}
          disabled={disabledStates.submitButton}
        >
          {edit ? 'Guardar cambios' : 'Crear'}
        </Button>
      </Box>

      <ConfirmModal
        title="Confirmación de creación"
        subtitle={
          <span>
            Estas por crear el user <b>{formState.mail}</b> con el rol{' '}
            <b>{translate(formState.role)}</b>. Recordá que para asignarle un nuevo rol, necesitas
            ingresar un mail diferente.
          </span>
        }
        acceptLabel="Aceptar"
        cancelLabel="Cancelar"
        loading={creatingOrUpdatingUser}
        open={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        handleAccept={handleSubmit}
      />
    </>
  );
};

export default withStyles(styles)(UserForm);
