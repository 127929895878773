import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Stack } from '@mui/material';
import EditEventForm from '../../../Components/Forms/EditEvent/EditEventForm';
import api from '../../../api/api';
import { NOTIFICATION_VARIANT, REQUEST_RESPONSE } from '../../../constants/types';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { validateEvent } from './eventValidations';
import permissions from '../../../Providers/Permissions/Permissions';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import Button from '../../../Components/Buttons/Button/Button';
import history from '../../../appHistory';

class EditEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrors: false,
      errorStatus: false,
      event: {
        name: '',
        status: '',
        promoImg: null,
        bannerImg: null,
        description: '',
        placeName: '',
        startDate: '',
        endDate: '',
        defaultStopOfSalesDate: '',
        producerId: '',
        shouldExtendStopOfSalesDateToInvitations: true,
        stadium: null,
        venue: null,
      },
      isLoading: false,
    };
  }

  async componentDidMount() {
    try {
      const { eventId } = this.props.urlParams;
      const { event } = await api.getEvent(eventId);
      const { stadium } = await api.getEventStadium(eventId);
      this.setState({
        event: { ...this.state.event, ...event, stadium },
      });
    } catch (error) {
      history.push('/error/404');
    }
  }

  handleEventChange = (values) => {
    this.setState({ event: { ...this.state.event, ...values } });
  };

  updateEvent = async ({
    Producer,
    TicketTypes,
    usersBlacklisted,
    stadium,
    venue,
    settings,
    ...event
  }) => {
    try {
      return api.updateEvent(event);
    } catch (err) {
      this.props.showNotification(
        'Ocurrió un error al actualizar el evento',
        NOTIFICATION_VARIANT.ERROR
      );
      throw err;
    }
  };

  updateStadium = async () => {
    const isSuperAdmin = permissions.isSuperAdmin(this.props.user.role);
    const { event } = this.state;

    if (!isSuperAdmin || !event.stadium) return;

    const sectionsToUpdate = event.stadium.sections.map(
      ({ ticketTypes, stockAvailable, ...section }) => ({
        eventId: event.id,
        ...section,
      })
    );

    const { status, esmessage } = await api.updateEventStadium(event.id, event.stadium.id, {
      sections: sectionsToUpdate,
    });

    if (status !== REQUEST_RESPONSE.SUCCESS) {
      this.props.showNotification(esmessage, NOTIFICATION_VARIANT.ERROR);
      throw new Error(esmessage);
    }
  };

  async uploadEvent() {
    this.setState({ isLoading: true });

    if (this.state.errorStatus) {
      this.setState({ showErrors: true, isLoading: false });
      this.props.showNotification('Existen errores en los campos', NOTIFICATION_VARIANT.ERROR);
      return;
    }

    if (!validateEvent(this.state.event, this.props.showNotification)) {
      this.setState({ isLoading: false });
      return;
    }

    try {
      await this.updateStadium();

      const { status, esmessage } = await this.updateEvent(this.state.event);

      if (status !== REQUEST_RESPONSE.SUCCESS) {
        this.props.showNotification(esmessage, NOTIFICATION_VARIANT.ERROR);
        this.setState({ isLoading: false });
        return;
      }

      this.props.showNotification(
        'Se actualizó el evento! Redirgiendo...',
        NOTIFICATION_VARIANT.SUCCESS
      );

      setTimeout(() => {
        history.push(
          `/backoffice/productoras/${this.state.event.producerId}/eventos/${this.state.event.id}`
        );
      }, 1000);
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  changeErrorStatus(status) {
    this.setState({ errorStatus: status });
  }

  render() {
    return (
      <PageContainer title="Backoffice - Editar Evento">
        <VBreadcrumbs pageTitle="Editar" />
        <Stack alignItems="center" gap={3} px={2}>
          <Typography variant="h3">Editar Evento</Typography>
          <EditEventForm
            event={this.state.event}
            user={this.props.user}
            handleEventChange={this.handleEventChange.bind(this)}
            changeError={this.changeErrorStatus.bind(this)}
            showErrors={this.state.showErrors}
            producerStadiums={this.state.event.stadium}
            edition
          />
          <Button
            fullwidth={false}
            title="Guardar"
            onClick={this.uploadEvent.bind(this)}
            loading={this.state.isLoading}
          />
        </Stack>
      </PageContainer>
    );
  }
}

export default EditEvent;
