import { useEffect, useState } from 'react';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import permissions from '../../../Providers/Permissions/Permissions';
import { NO_STADIUM_ID, NOT_BLACKLISTED } from './EditEventForm.constants';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { FLAGS } from '../../../constants/constants';

export const useEditEventForm = (event, producerStadiums, handleEventChange, changeError) => {
  const [errors, setErrors] = useState({
    name: false,
    status: false,
    promoImg: false,
    bannerImg: false,
    description: false,
    placeName: false,
    startDate: false,
    endDate: false,
    defaultStopOfSalesDate: false,
  });
  const [venuesOn, setVenuesOn] = useState(false);

  const { user } = useAuth();

  const getVenuesFlag = async () => {
    await handleRequestHelper({
      endpoint: () => api.getFlag(FLAGS.VENUES_ENABLED),
      onSuccess: ({ flag }) => setVenuesOn(flag.context.enabled),
      onFailure: () => setVenuesOn(false),
    });
  };

  useEffect(() => {
    getVenuesFlag();
  }, []);

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    let inputValue = value;
    if (value === '') inputValue = null;
    handleEventChange({ [name]: inputValue });
  };

  const handleStadiumChange = ({ target }) => {
    const { value } = target;
    let stadium = null;
    if (value !== NO_STADIUM_ID) {
      const producersStadiumsCopy = JSON.parse(JSON.stringify(producerStadiums));
      stadium = producersStadiumsCopy.find(({ id }) => id === value);
    }

    handleEventChange({ stadium });
  };

  const handleSellersBlacklistChange = (event) => {
    const { value, name } = event.target;
    const shouldBlacklistSellers = value === NOT_BLACKLISTED;
    handleEventChange({ [name]: shouldBlacklistSellers });
  };

  const handleImgChange = (url, name) => {
    handleEventChange({ [name]: url });
  };

  const handleDateChange = (date) => (value) => {
    handleEventChange({ [date]: value });
  };

  const handleCheckBoxChange = (checkBox, check) => {
    handleEventChange({ [checkBox]: check });
  };

  const changeErrorCurrentStatus = (input, error, errorFields) => {
    errorFields = Object.keys(errorFields);

    if (errors[input] !== error) {
      let showErrorsState = error;

      if (errorFields.includes(input)) {
        if (errorFields.length > 1) showErrorsState = true;
      }

      setErrors({ ...errors, [input]: error });
      changeError(showErrorsState);
    }
  };

  const checkInputError = (value, input, getHelperText) => {
    const helperText = getHelperText(value);
    const error = helperText !== '';

    const errorState = errors;
    const errorFields = Object.keys(errorState).reduce((arr, key) => {
      if (errorState[key]) arr[key] = errorState[key];
      return arr;
    }, {});

    changeErrorCurrentStatus(input, error, errorFields);

    return helperText;
  };

  let producerStadiumOptions = [];
  if (producerStadiums && producerStadiums.length) {
    producerStadiumOptions = producerStadiums.map(({ name, capacity, numberOfSections, id }) => {
      const capacityLabel = permissions.isSuperAdmin(user.role) ? ` - ${capacity}p` : '';
      return {
        label: `${name} ${capacityLabel} - ${numberOfSections} sectores`,
        value: id,
      };
    });

    producerStadiumOptions.unshift({ label: 'Sin planimetria', value: NO_STADIUM_ID });
  }

  return {
    handleInputChange,
    handleStadiumChange,
    handleSellersBlacklistChange,
    handleImgChange,
    handleDateChange,
    handleCheckBoxChange,
    producerStadiumOptions,
    checkInputError,
    user,
    venuesOn,
  };
};
