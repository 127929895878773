import React from 'react';
import { Stack } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import Button from '../../../../Components/Buttons/Button/Button';
import VModal from '../../../../Venti-UI-Kit/VModal/VModal';
import TextInput from '../../../../Venti-UI-Kit/Inputs/TextInput';
import SelectInput from '../../../../Venti-UI-Kit/Inputs/SelectInput';
import { TYPES_OPTIONS } from './FinancialOperationsTable.constants';
import VTextArea from '../../../../Venti-UI-Kit/VInputs/VTextArea/VTextArea';
import { useCreateFinancialOperationModal } from './CreateFinancialOperationModal.hook';
import CustomDatePicker from '../../../../Components/DatePickerComponents/CustomDatePicker';
import { CURRENCY } from '../../../../constants/types';

const CreateFinancialOperationModal = ({ open, handleAccept, handleClose, defaultCurency }) => {
  const { formState, handleInputChange, handleCreate, handleCancel, handlePaymentDateChange } =
    useCreateFinancialOperationModal(handleAccept, handleClose);

  return (
    <VModal open={open} title="Agregar una operación financiera" onClose={handleClose} width={600}>
      <Stack gap={0.5} marginBottom={4}>
        <SelectInput
          options={TYPES_OPTIONS}
          title="Tipo"
          name="type"
          onChange={handleInputChange}
          value={formState.type.value}
          errorMessage={formState.type.error}
        >
          {TYPES_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </SelectInput>
        <TextInput
          onChange={handleInputChange}
          defaultValue={formState.amount.value}
          title="Cantidad"
          name="amount"
          type="number"
          startAdornment={defaultCurency === CURRENCY.ARS ? '$' : 'US$'}
          errorMessage={formState.amount.error}
        />
        <VTextArea
          onChange={handleInputChange}
          label="Notas"
          name="notes"
          value={formState.notes.value}
        />
        <CustomDatePicker
          title="Fecha de pago"
          value={formState.paymentDate.value || null}
          onChange={handlePaymentDateChange}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          format="dd/MM/yyyy"
        />
      </Stack>
      <Stack spacing={2}>
        <Button fullWidth title="Cancelar" onClick={handleCancel} variant="outlined" />
        <Button fullWidth title="Crear" onClick={handleCreate} />
      </Stack>
    </VModal>
  );
};

export default CreateFinancialOperationModal;
