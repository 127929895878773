import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './FinancialOperationsPage.styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { useFinancialOperationsPage } from './FinancialOperationsPage.hook';
import FinancialOperationsTable from './FinancialOperationsTable/FinancialOperationsTable';
import Button from '../../../Components/Buttons/Button/Button';
import CreateFinancialOperationModal from './FinancialOperationsTable/CreateFinancialOperationModal';
import SettlementPreview from '../../../Components/Settlements/SettlementPreview/SettlementPreview';

const FinancialOperationsPage = ({ urlParams }) => {
  const {
    financialOperations,
    setFinancialOperations,
    initialFinancialOperations,
    setInitialFinancialOperations,
    openCreateModal,
    handleOpenCreateModal,
    handleCloseCreateModal,
    handleCreateFinancialOperation,
    eventCurrency,
  } = useFinancialOperationsPage(urlParams);

  return (
    <PageContainer title="Backoffice - Liquidaciones">
      <VBreadcrumbs pageTitle="Liquidaciones" />
      <Stack alignItems="center" gap={3} px={{ xs: 2, md: 8 }} width="100%">
        <VTypography variant="h2">Liquidaciones</VTypography>
        <VTypography variant="h4" align="left" width="100%">
          Adelantos y Servicios
        </VTypography>
        <Stack alignSelf={{ xs: 'center', sm: 'end' }}>
          <Button title="Agregar operación" onClick={handleOpenCreateModal} />
        </Stack>
        <FinancialOperationsTable
          financialOperations={financialOperations}
          setFinancialOperations={setFinancialOperations}
          initialFinancialOperations={initialFinancialOperations}
          setInitialFinancialOperations={setInitialFinancialOperations}
          eventCurrency={eventCurrency}
        />
        <SettlementPreview urlParams={urlParams} />
      </Stack>
      <CreateFinancialOperationModal
        open={openCreateModal}
        handleClose={handleCloseCreateModal}
        handleAccept={handleCreateFinancialOperation}
        defaultCurency={eventCurrency}
      />
    </PageContainer>
  );
};

export default withStyles(styles)(FinancialOperationsPage);
