import React from 'react';
import SelectInput from '../../Venti-UI-Kit/Inputs/SelectInput';

const TicketQuantitySelector = ({ ticketId, value, onSelect, canBeSelled, maxQuantity }) => {
  const ticketsQuantityOptions = Array.from({ length: maxQuantity }, (_, i) => ({
    label: i + 1,
    value: i + 1,
  }));

  return (
    <SelectInput
      value={value}
      id={ticketId}
      disabled={!canBeSelled}
      onChange={onSelect}
      autoSelect={false}
      fullWidth={false}
      options={ticketsQuantityOptions}
      placeholder={canBeSelled ? 'Seleccionar' : 'Sin Stock'}
      variant="outlined"
    />
  );
};

export default TicketQuantitySelector;
