import React, { createContext, useContext, useMemo } from 'react';
import { useAuthContext } from './AuthProvider.hook';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { user, getUserIntercomHash, login, logout, checkJWTToken } = useAuthContext();
  const authValue = useMemo(
    () => ({ user, getUserIntercomHash, login, logout, checkJWTToken }),
    [user]
  );

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
