import React, { useEffect, useRef } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Box, Checkbox, MenuItem, Select, Stack, ListSubheader } from '@mui/material';
import styles from './SelectInput.styles';
import VTypography from '../VTypography/VTypography';

const SelectInput = ({
  classes,
  fullWidth = true,
  variant = 'default',
  options = [],
  title,
  leftIcon,
  color,
  errorMessage,
  hint,
  width,
  flex,
  required = false,
  children,
  showHelpText = true,
  description,
  autoSelect = true,
  ...props
}) => {
  const currentValue = props.value || (props.multiple ? [] : '');
  const selectRef = useRef();
  const theme = useTheme();
  const errorClass = errorMessage ? 'error' : '';

  const findOptionByValue = (value) => options.find((item) => item.value === value);

  const handleChange = ({ target: { value } }) => {
    props.onChange({ target: { name: props.name, value } });
  };

  const handleOnClose = () => {
    if (selectRef.current) {
      selectRef.current.classList.remove('Mui-focused');
    }
  };

  const getRenderValueComponent = () => {
    let textToRender = findOptionByValue(currentValue)?.label;

    if (props.placeholder && !currentValue && !currentValue.length)
      textToRender = props.placeholder;
    else if (props.multiple) {
      const selectedOptions = currentValue.map((value) => findOptionByValue(value)?.label);
      textToRender = selectedOptions.join(', ');
    }
    return <VTypography linesNumber={1}>{textToRender}</VTypography>;
  };

  useEffect(() => {
    if (autoSelect) props.onChange({ target: { name: props.name, value: currentValue } });
  }, []);

  return (
    <Stack
      className={classes.inputContainer}
      flex={flex}
      width={width || (fullWidth ? '100%' : 240)}
    >
      {title && (
        <Stack direction="row" mb={1} gap={0.4} height={16} maxHeight={16} alignItems="center">
          {leftIcon}
          <VTypography variant="body1">{title}</VTypography>
          {required && (
            <VTypography variant="body2" color={theme.palette.error.main}>
              *
            </VTypography>
          )}
        </Stack>
      )}
      {description && (
        <VTypography variant="subtitle6" color={theme.palette.grey[600]}>
          {description}
        </VTypography>
      )}
      <Select
        {...props}
        displayEmpty
        size="small"
        MenuProps={{ className: classes.menu, disableScrollLock: true }}
        value={currentValue}
        ref={selectRef}
        onClose={handleOnClose}
        className={`selectContainer ${variant} ${color} ${errorClass}`}
        onChange={handleChange}
        renderValue={getRenderValueComponent}
      >
        {children ||
          options.map(({ label, value, isCategory }) =>
            isCategory ? (
              <ListSubheader key={`${label} ${value}`}>{label}</ListSubheader>
            ) : (
              <MenuItem key={`${label} ${value}`} value={value}>
                {props.multiple && <Checkbox checked={currentValue.includes(value)} />}
                {label}
              </MenuItem>
            )
          )}
      </Select>
      {showHelpText && (
        <Box className={classes.bottomInputBox}>
          <VTypography variant="caption" color={errorMessage && 'error'}>
            {errorMessage || hint}
          </VTypography>
        </Box>
      )}
    </Stack>
  );
};

export default withStyles(styles)(SelectInput);
