import { useCallback, useEffect, useRef, useState } from 'react';
import { differenceInYears, parse } from 'date-fns';
import { REGULAR_EXPRESSION } from '../constants/constants';
import { INPUT_TYPE } from '../constants/types';
import { IDENTIFICATION_TYPES_VALIDATIONS } from '../constants/identificationTypes';
import { isValidMail } from '../utils/utils';

export const useForm = (initialState = {}, dependencies = []) => {
  const formatInitialState = {};
  const initialErrorsState = {};
  const initialValuesState = {};

  Object.entries(initialState).forEach(
    ([field, { value = '', title, type, required = true, customValidation }]) => {
      formatInitialState[field] = {
        title,
        type,
        value,
        error: '',
        required,
        customValidation,
      };
      initialErrorsState[field] = '';
      initialValuesState[field] = '';
    }
  );

  const [formState, setFormState] = useState(formatInitialState);

  const errorsState = useRef(initialErrorsState);

  const resetForm = () => {
    setFormState(formatInitialState);
    errorsState.current = initialErrorsState;
  };

  const isFormComplete = useCallback(() => {
    for (const field of Object.keys(formState)) {
      if (!formState[field].value && formState[field].required) return false;
    }
    return true;
  }, [formState]);

  const handleInputChange = ({ target }) => {
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setFormState({
      ...formState,
      [target.name]: {
        ...formState[target.name],
        value,
      },
    });
  };

  const validatePassword = () => {
    const password = formState.password.value;
    const mail = formState.mail?.value || '';

    if (!password.match(REGULAR_EXPRESSION.PASSWORD_LENGHT_VALIDATION))
      return 'La contraseña debe tener al menos 8 caracteres.';

    if (!password.match(REGULAR_EXPRESSION.PASSWORD_VALIDATION))
      return 'La contraseña debe tener al menos una mayúscula, una minúscula, dos números y un carácter especial.';

    if (password.trim() === mail.trim()) return 'La contraseña no puede ser igual al mail.';

    return '';
  };

  const validateConfirmPassword = () => {
    const password = formState.password.value;
    const confirmPassword = formState.confirmPassword.value;
    return password === confirmPassword ? '' : 'Las contraseñas no coinciden.';
  };

  const validateMail = () => {
    const mail = formState.mail.value;
    if (mail !== mail.trim()) return 'El email no puede contener espacios.';
    return isValidMail(mail) ? '' : 'Este formato de email no es válido.';
  };

  const validateConfirmMail = () => {
    const mail = formState.mail.value.trim();
    const confirmMail = formState.confirmMail.value.trim();
    return mail === confirmMail ? '' : 'Los mails no coinciden.';
  };

  const validateAlias = () => {
    const alias = formState.alias.value;
    return alias.trim().match(REGULAR_EXPRESSION.ALIAS_VALIDATION)
      ? ''
      : 'El formato del alias es inválido, solo se permiten letras, números, guiones altos y bajos';
  };

  const validateAlphaText = (string) => {
    return string.trim().match(REGULAR_EXPRESSION.TEXT_VALIDATION)
      ? ''
      : 'Solo se permiten letras.';
  };

  const validateNumber = (number) => {
    return number.toString().replace(/\s/g, '').trim().match(REGULAR_EXPRESSION.NUMBER_VALIDATION)
      ? ''
      : 'Solo se permiten solo números.';
  };

  const validateFloat = (number) => {
    return number.toString().replace(/\s/g, '').trim().match(REGULAR_EXPRESSION.FLOAT)
      ? ''
      : 'Solo se permiten solo números.';
  };

  const validateAlphaNumeric = (string) => {
    return string.toString().trim().match(REGULAR_EXPRESSION.TEXT_AND_NUMBERS_VALIDATION)
      ? ''
      : 'Solo se permiten letras y números.';
  };

  const validateIdNumber = (number) => {
    const { regex, errorMessage } = IDENTIFICATION_TYPES_VALIDATIONS[formState.idType.value];

    return number.toString().trim().match(regex) ? '' : errorMessage;
  };

  const validateDate = (string) => {
    if (!string.match(REGULAR_EXPRESSION.DATE_VALIDATION)) return 'La fecha contiene espacios.';

    const [month, year] = string.split('/');
    const date = `${month}/01/${year}`;
    if (isNaN(Date.parse(date))) return 'Ingrese una fecha válida.';
    if (new Date(date) <= new Date()) return 'Ingrese una fecha no expirada.';
    return '';
  };

  const validateBirthDate = (string) => {
    if (!string.match(REGULAR_EXPRESSION.DATE_VALIDATION)) return 'Formato inválido.';

    const birthDate = parse(string, 'dd/MM/yyyy', new Date());
    if (isNaN(birthDate)) return 'Ingrese una fecha válida.';
    const age = differenceInYears(new Date(), birthDate);
    if (age < 18) return 'Debe ser mayor de 18 años.';
    return '';
  };

  const inputTypeValidation = (value, type) => {
    if (!value) return '';

    switch (type) {
      case INPUT_TYPE.MAIL:
        return validateMail();
      case INPUT_TYPE.CONFIRM_MAIL:
        return validateConfirmMail();
      case INPUT_TYPE.PASSWORD:
        return validatePassword();
      case INPUT_TYPE.CONFIRM_PASSWORD:
        return validateConfirmPassword();
      case INPUT_TYPE.ALIAS:
        return validateAlias();
      case INPUT_TYPE.TEXT:
        return '';
      case INPUT_TYPE.ALPHA_NUMERIC:
        return validateAlphaNumeric(value);
      case INPUT_TYPE.NUMBER:
        return validateNumber(value);
      case INPUT_TYPE.FLOAT:
        return validateFloat(value);
      case INPUT_TYPE.DATE:
        return validateDate(value);
      case INPUT_TYPE.ALPHA:
        return validateAlphaText(value);
      case INPUT_TYPE.ID_NUMBER:
        return validateIdNumber(value);
      case INPUT_TYPE.BIRTH_DATE:
        return validateBirthDate(value);
      default:
        return '';
    }
  };

  const validateInputType = (field) => {
    const { value, type, customValidation } = formState[field];
    errorsState.current[field] = errorsState.current[field] || inputTypeValidation(value, type);

    if (customValidation)
      errorsState.current[field] = errorsState.current[field] || customValidation(value);
  };

  const validateInputsType = () => {
    Object.keys(formState).forEach((field) => validateInputType(field));
  };

  const validateRequiredInput = (field) => {
    const { value, required } = formState[field];
    errorsState.current[field] = required && !value ? 'Este campo es requerido.' : '';
  };

  const validateRequiredInputs = () => {
    Object.keys(formState).forEach((field) => validateRequiredInput(field));
  };

  const setInputError = (field, error = '') => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        error: errorsState.current[field] || error,
      },
    }));
  };

  const setFormErrors = () => {
    Object.keys(formState).forEach((field) => setInputError(field));
  };

  const formHasErrors = () => Object.values(errorsState.current).some((error) => error);

  const validateFormInputs = () => {
    validateRequiredInputs();
    validateInputsType();
    setFormErrors();
    return !formHasErrors();
  };

  const updateInitialStateByDependencies = () => {
    if (Object.keys(initialState).length > 0) {
      setFormState((prevState) => {
        const newState = { ...prevState };
        Object.entries(initialState).forEach(([field, { value }]) => {
          if (newState[field]) newState[field].value = value;
        });
        return newState;
      });
    }
  };

  const getFormValues = () => {
    return Object.entries(formState).reduce((formValues, [field, { value }]) => {
      formValues[field] = value;
      return formValues;
    }, {});
  };

  useEffect(() => updateInitialStateByDependencies(), dependencies);

  return {
    formState,
    formValues: getFormValues(),
    isFormComplete,
    validateFormInputs,
    handleInputChange,
    resetForm,
    setInputError,
  };
};
