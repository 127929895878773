import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './EventReportsStyles';
import SalesTicketsPerDayTable from '../../../Components/ReportComponents/ReportTables/SalesTicketsPerDayTable';
import RevenueTable from '../../../Components/ReportComponents/RevenueTable/RevenueTable';
import TicketTypeBarChart from '../../../Components/ReportComponents/TicketTypesBarChart/TicketTypeBarChart';
import SalesByTimeLineChart from '../../../Components/ReportComponents/SalesByTimeLineChart/SalesByTimeLineChart';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';
import TicketTypesAccordion from '../../../Components/ReportComponents/Accordions/TicketTypesReportsDataAccordion/TicketTypesReportsDataAccordion';
import { REPORTS_DATA_TYPE, USER_PERMISSIONS } from '../../../constants/types';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import { handleRequestHelper } from '../../../utils/helpers';
import TicketSalesTable from '../../../Components/ReportComponents/ReportTables/TicketSalesTable';
import SellersSalesTable from '../../../Components/ReportComponents/ReportTables/SellersSalesTable';
import SellersInvitationsTable from '../../../Components/ReportComponents/ReportTables/SellersInvitationsTable';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import { useAuth } from '../../../Providers/AuthProvider/AuthProvider';
import { validateUserRole } from '../../../utils/utils';
import api from '../../../api/api';

const EventReports = ({ urlParams, classes }) => {
  const { user } = useAuth();
  const { eventId } = urlParams;
  const [eventReport, setEventReport] = useState();
  const [isLoadingReports, setIsLoadingReports] = useState(false);
  const isAdmin = validateUserRole(user.role, USER_PERMISSIONS.ADMIN_WITH_READ_ONLY);

  const getEventReport = async () => {
    setIsLoadingReports(true);
    await handleRequestHelper({
      endpoint: () => api.getEventReport(eventId),
      onSuccess: ({ report }) => setEventReport(report),
      onFailure: () => {
        setIsLoadingReports(false);
        setEventReport(null);
      },
    });
    setIsLoadingReports(false);
  };

  useEffect(() => {
    getEventReport();
  }, []);

  const {
    eventCashSales,
    eventInfo,
    eventInvitationSales,
    eventInvitationsBySeller,
    eventOnlineSales,
    eventSalesAndUsedGroupByTicketType,
    eventSalesBySeller,
    eventSalesByTicketId,
    eventInvitationsTicketsById,
    eventSalesGroupByTimePeriod,
    eventSalesPerDay,
    eventTotalSales,
  } = eventReport ?? {};

  return (
    <PageContainer user={user} title="Backoffice - Reportes">
      <div className={classes.pageContainer}>
        <VBreadcrumbs pageTitle="Reportes" />
        <div className={classes.contentContainer}>
          {isLoadingReports ? (
            <>
              <Typography className={classes.subtitle}>Cargando reportes...</Typography>
              <CustomCircularProgress />
            </>
          ) : !eventReport ? (
            <Typography className={classes.subtitle}>
              No se pudo obtener la información de los reportes
            </Typography>
          ) : (
            <>
              <Typography variant="h3">Reporte Evento: {eventInfo.name}</Typography>
              <RevenueTable revenueData={eventTotalSales} />
              <div className={classes.accordionsContainer}>
                <TicketTypesAccordion
                  data={eventSalesAndUsedGroupByTicketType}
                  title="Detalle de venta y envío de tickets"
                  subtitle="Tickets disponibles:"
                />
                {isAdmin && (
                  <TicketTypesAccordion
                    data={eventSalesAndUsedGroupByTicketType}
                    title="Detalle de ingresados al evento"
                    subtitle="Faltan ingresar:"
                    dataType={REPORTS_DATA_TYPE.ACCESSES}
                  />
                )}
              </div>
              <div className={classes.paper}>
                <Typography className={classes.paperTitle}>Cantidad de ventas</Typography>
                <TicketTypeBarChart ticketTypeList={Object.values(eventSalesByTicketId)} />
              </div>
              <div className={classes.paper}>
                <Typography className={classes.paperTitle}>Cantidad de invitaciones</Typography>
                <TicketTypeBarChart ticketTypeList={Object.values(eventInvitationsTicketsById)} />
              </div>
              {eventSalesGroupByTimePeriod && (
                <div className={classes.paper}>
                  <Typography className={classes.paperTitle}>Ingresos por fecha</Typography>
                  <SalesByTimeLineChart data={eventSalesGroupByTimePeriod} />
                </div>
              )}
              {isAdmin && (
                <div className={classes.paper}>
                  <Typography className={classes.paperTitle}>Detalle de ventas por día</Typography>
                  <SalesTicketsPerDayTable data={eventSalesPerDay} />
                </div>
              )}
              {eventCashSales && (
                <div className={classes.paper}>
                  <Typography className={classes.paperTitle}>Detalle de ventas cash</Typography>
                  <TicketSalesTable data={eventCashSales} tableType="CashSales" />
                </div>
              )}
              {eventOnlineSales && (
                <div className={classes.paper}>
                  <Typography className={classes.paperTitle}>Detalle de ventas online</Typography>
                  <TicketSalesTable data={eventOnlineSales} tableType="OnlineSales" />
                </div>
              )}
              {eventInvitationSales && (
                <div className={classes.paper}>
                  <Typography className={classes.paperTitle}>
                    Detalle de invitaciones enviadas
                  </Typography>
                  <TicketSalesTable data={eventInvitationSales} tableType="Invitations" />
                </div>
              )}
              {eventSalesBySeller && (
                <div className={classes.paper}>
                  <Typography className={classes.paperTitle}>
                    Detalle de ventas por pública
                  </Typography>
                  <SellersSalesTable data={eventSalesBySeller} />
                </div>
              )}
              {eventInvitationsBySeller && (
                <div className={classes.paper}>
                  <Typography className={classes.paperTitle}>
                    Detalle de invitaciones ingresadas por pública
                  </Typography>
                  <SellersInvitationsTable data={eventInvitationsBySeller} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </PageContainer>
  );
};
export default withStyles(styles)(EventReports);
