import React, { useMemo, useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core';
import { Box, IconButton, Stack, TextField } from '@mui/material';
import InputMask from 'react-input-mask';
import styles from './InputsStyles';
import { INPUT_TYPE, MATERIAL_UI_ICONS } from '../../constants/types';
import VTypography from '../VTypography/VTypography';
import VIcon from '../VIcon/VIcon';

const TextInput = ({
  classes,
  title,
  errorMessage,
  successMessage,
  leftIcon,
  type,
  onChange,
  onFocus,
  mask,
  upperCase = false,
  fullWidth = true,
  hint,
  flex,
  defaultValue,
  maxLength,
  maskChar = '‎',
  required = false,
  disabled = false,
  startAdornment,
  endAdornment,
  description,
  readOnly = false,
  showHelpText = true,
  value,
  ...props
}) => {
  const isPasswordInput = type === INPUT_TYPE.PASSWORD;
  const [showPassword, setShowPassword] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();

  const inputType = useMemo(() => {
    return type === INPUT_TYPE.PASSWORD && showPassword ? INPUT_TYPE.TEXT : type;
  }, [type, showPassword]);

  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Stack flex={flex} width={fullWidth ? '100%' : 240}>
      <Stack>
        {title && (
          <Stack className={classes.textInputTitleContainer} direction="row">
            {leftIcon}
            <VTypography variant="body1">{title}</VTypography>
            {required && (
              <VTypography variant="body2" color={theme.palette.error.main}>
                *
              </VTypography>
            )}
          </Stack>
        )}
        {description && (
          <VTypography variant="subtitle6" color={theme.palette.grey[600]}>
            {description}
          </VTypography>
        )}
      </Stack>
      <InputMask
        mask={mask}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        maskChar={maskChar}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        {(inputProps) => (
          <TextField
            defaultValue={defaultValue}
            onChange={onChange}
            className={`
              ${classes.textInputContainer}
              ${errorMessage && classes.error}
              ${disabled && classes.disabled} 
              ${successMessage && classes.success}`}
            sx={{ ...(upperCase && { textTransform: 'uppercase' }) }}
            disabled={disabled}
            InputProps={{
              inputProps: { maxLength, ...inputProps },
              startAdornment,
              readOnly,
              value,
              endAdornment:
                endAdornment ||
                (isPasswordInput && (
                  <IconButton
                    className={classes.rightIcon}
                    disabled={disabled}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <VIcon
                      baseClassName={MATERIAL_UI_ICONS.DEFAULT}
                      name={showPassword ? 'visibility' : 'visibility_off'}
                    />
                  </IconButton>
                )),
            }}
            type={inputType}
            {...props}
          />
        )}
      </InputMask>

      {showHelpText && (
        <Box className={classes.bottomInputBox}>
          <VTypography
            variant="caption"
            color={(errorMessage && 'error') || (successMessage && 'success')}
          >
            {errorMessage || successMessage || (isFocused && hint)}
          </VTypography>
        </Box>
      )}
    </Stack>
  );
};

export default withStyles(styles)(TextInput);
