import api from '../../../../../api/api';
import { LOCAL_STORAGE_VARIABLES } from '../../../../../constants/constants';
import { MP_IDENTIFICATION_TYPES } from '../../../../../constants/identificationTypes';
import { handleRequestHelper } from '../../../../../utils/helpers';
import { removeStringSpaces } from '../../../../../utils/utils';
import { getCardType, getMonthAndYearFromCardExpirationDate } from '../creditCardUtils';
import { PaymentMethod } from './PaymentMethod';

export class MercadoPagoPaymentMethod extends PaymentMethod {
  constructor(order, showNotification) {
    super(order, showNotification);
    const publicKey = localStorage.getItem(LOCAL_STORAGE_VARIABLES.STORED_MERCADO_PAGO_KEY);
    this.mp = new MercadoPago(publicKey, {
      locale: 'es-AR',
    });
    this.paymentMethodId = '';
  }

  async getIdentificationTypes() {
    try {
      const identificationTypes = await this.mp.getIdentificationTypes();
      return identificationTypes.map(({ id }) => ({
        label: MP_IDENTIFICATION_TYPES[id].label,
        value: MP_IDENTIFICATION_TYPES[id].value,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async getInstallments() {
    return [];
  }

  getAvailableCards() {
    return [];
  }

  async getPaymentMethodData(cardNumber) {
    const bin = cardNumber.slice(0, 8);
    const { results } = await this.mp.getPaymentMethods({ bin });
    this.paymentMethodId = results[0].id;
  }

  async validateCardNumber(cardNumber) {
    let cardNumberError = '';
    const { cardLength, isLemonCard } = getCardType(cardNumber);
    const cardNumberIsComplete = cardNumber.length === cardLength;

    if (cardNumberIsComplete) {
      if (this.order.isLemonExclusive && !isLemonCard) {
        cardNumberError = 'Método de pago inválido. Compra exclusiva con Brubank';
      } else {
        await this.getPaymentMethodData(cardNumber);
      }
    }

    return { cardNumberIsComplete, cardNumberError };
  }

  async getCardToken({ cardNumber, cardName, cardExpirationDate, cvc, idType, idValue }) {
    const { month, year } = getMonthAndYearFromCardExpirationDate(cardExpirationDate);

    try {
      const { id } = await this.mp.createCardToken({
        cardNumber,
        cardholderName: cardName,
        cardExpirationMonth: month,
        cardExpirationYear: year,
        securityCode: cvc,
        identificationType: idType,
        identificationNumber: idValue,
      });
      return id;
    } catch (error) {
      console.error(error);
    }
  }

  async processPayment({ data, onSuccess, onFailure }) {
    const { cardNumber, cardName, cardExpirationDate, cvc, idType, idValue } = data;
    const cardNumberWithoutSpaces = removeStringSpaces(cardNumber.value);
    const cardToken = await this.getCardToken({
      cardNumber: cardNumberWithoutSpaces,
      cardName: cardName.value,
      cardExpirationDate: cardExpirationDate.value,
      cvc: cvc.value,
      idType: idType.value,
      idValue: idValue.value,
    });

    const [orderUserName, orderUserLastname] = this.order.name.split(' - ');

    const paymentData = {
      orderId: this.order.id,
      bin: cardNumberWithoutSpaces.substring(0, 8),
      token: cardToken,
      payment_method_id: this.paymentMethodId,
      payer: {
        email: this.order.mail,
        first_name: orderUserName,
        last_name: orderUserLastname,
        identification: idValue.value,
      },
    };

    return handleRequestHelper({
      endpoint: () => api.payMercadoPagoOrder(paymentData.orderId, paymentData),
      onSuccess: () => onSuccess(),
      onFailure: () => onFailure(),
      showNotification: this.showNotification,
    });
  }
}
