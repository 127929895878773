import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { useNotifications } from '../../../Providers/NotificationsProvider/NotificationsProvider';
import { useForm } from '../../../hooks/useForm';
import { copyToClipboard } from '../../../utils/utils';
import { SCAN_OPTION, SCAN_STATE } from './types';
import { NOTIFICATION_VARIANT } from '../../../constants/types';

export const useAdvancedEventSettingsPage = () => {
  const { eventId } = useParams();
  const { showNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(true);
  const eventSettings = useRef({});
  const gateSecretApiKey = useRef('');

  const mapScanStateToOption = (scanOptions) => {
    if (!scanOptions) return;

    const { scanApp, turnstileGate } = scanOptions;
    if (scanApp === SCAN_STATE.BURN && turnstileGate === SCAN_STATE.BURN) return SCAN_OPTION.ALL;
    if (scanApp === SCAN_STATE.BURN) return SCAN_OPTION.SCAN_APP;
    if (turnstileGate === SCAN_STATE.BURN) return SCAN_OPTION.TURNSTILE_GATE;
  };

  const mapOptionToScanState = (scanOption) => {
    switch (scanOption) {
      case SCAN_OPTION.ALL:
        return { scanApp: SCAN_STATE.BURN, turnstileGate: SCAN_STATE.BURN };
      case SCAN_OPTION.TURNSTILE_GATE:
        return { scanApp: SCAN_STATE.VALIDATE, turnstileGate: SCAN_STATE.BURN };
      case SCAN_OPTION.SCAN_APP:
        return { scanApp: SCAN_STATE.BURN, turnstileGate: SCAN_STATE.VALIDATE };
      default:
        return eventSettings.current.scanOptions;
    }
  };

  const validationModeOptions = [
    { label: 'Molinetes y app de escaneo', value: SCAN_OPTION.ALL },
    { label: 'Solo molinetes', value: SCAN_OPTION.TURNSTILE_GATE },
    { label: 'Solo app de escaneo', value: SCAN_OPTION.SCAN_APP },
  ];

  const { formState, handleInputChange, formValues } = useForm(
    {
      secondaryMarketEnable: { value: eventSettings.current.secondaryMarketEnable },
      secondaryMarketEventButtonEnable: {
        value: eventSettings.current.secondaryMarketEventButtonEnable,
      },
      // shouldBlacklistSellers: { value: eventSettings.current.shouldBlacklistSellers },
      // shouldExtendStopOfSalesToInvitations: {
      //   value: eventSettings.current.shouldExtendStopOfSalesToInvitations,
      // },
      // shouldConvertPriceForUsd: { value: eventSettings.current.shouldConvertPriceForUsd },
      // hoursBeforeRelease: { value: eventSettings.current.hoursBeforeRelease },
      // hoursBeforeReleaseSecondaryMarket: { value: eventSettings.current.hoursBeforeReleaseSecondaryMarket },
      scanOptions: { value: mapScanStateToOption(eventSettings.current.scanOptions) },
    },
    [eventSettings.current, gateSecretApiKey]
  );

  const getEventSettings = async () => {
    await handleRequestHelper({
      endpoint: () => api.getEventSettings(eventId),
      onSuccess: ({ settings }) => {
        eventSettings.current = settings;
      },
      showNotification,
    });
  };

  const getGateSecretApiKey = async () => {
    await handleRequestHelper({
      endpoint: () => api.generateGateSecretApiKey(eventId),
      onSuccess: ({ secret }) => {
        gateSecretApiKey.current = secret;
      },
      showNotification,
    });
  };

  const getInitialData = async () => {
    setIsLoading(true);
    await getGateSecretApiKey();
    await getEventSettings();
    setIsLoading(false);
  };

  const copySecretApiKeyToClipboard = () => {
    copyToClipboard(gateSecretApiKey.current, showNotification, 'Secret Api Key copiada!');
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const updateEventSettings = async () => {
    await handleRequestHelper({
      endpoint: () =>
        api.updateEventSettings(eventId, {
          ...formValues,
          scanOptions: mapOptionToScanState(formValues.scanOptions),
        }),
      onSuccess: () =>
        showNotification('Configuración actualizada correctamente', NOTIFICATION_VARIANT.SUCCESS),
      showNotification,
    });
  };

  return {
    formState,
    validationModeOptions,
    isLoading,
    gateSecretApiKey: gateSecretApiKey.current,
    handleInputChange,
    updateEventSettings,
    copySecretApiKeyToClipboard,
  };
};
