import React from 'react';
import { withStyles } from '@material-ui/core';
import { Stack } from '@mui/material';
import styles from './styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import VBreadcrumbs from '../../../Venti-UI-Kit/VBreadcrumbs/VBreadcrumbs';
import TitledSwitch from '../../../Venti-UI-Kit/Switches/TitledSwitch';
import SelectInput from '../../../Venti-UI-Kit/Inputs/SelectInput';
import Button from '../../../Components/Buttons/Button/Button';
import { useAdvancedEventSettingsPage } from './hook';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import TextInput from '../../../Venti-UI-Kit/Inputs/TextInput';
import IconButton from '../../../Components/Buttons/IconButton/IconButton';
import VIcon from '../../../Venti-UI-Kit/VIcon/VIcon';
import { INPUT_TYPE } from '../../../constants/types';
import CustomCircularProgress from '../../../Components/CustomCircularProgress/CustomCircularProgress';

const AdvancedEventSettingsPage = ({ classes }) => {
  const {
    formState,
    validationModeOptions,
    gateSecretApiKey,
    isLoading,
    handleInputChange,
    updateEventSettings,
    copySecretApiKeyToClipboard,
  } = useAdvancedEventSettingsPage();

  if (isLoading)
    return (
      <PageContainer title="Backoffice - Opciones Avanzadas">
        <VBreadcrumbs pageTitle="Opciones Avanzadas" />
        <CustomCircularProgress size={40} />
      </PageContainer>
    );

  return (
    <PageContainer title="Backoffice - Opciones Avanzadas">
      <VBreadcrumbs pageTitle="Opciones Avanzadas" />
      <Stack padding={2} alignItems="start" maxWidth={990} alignSelf="center">
        <VTypography variant="h3">Configuración avanzada del evento</VTypography>
        <Stack className={classes.pageContent}>
          <TextInput
            title="Secret Gate API Key"
            description="Secret API Key necesaría para poder vincular los molinetes"
            name="gateSecretApiKey"
            readOnly
            type={INPUT_TYPE.PASSWORD}
            value={gateSecretApiKey}
            showHelpText={false}
            endAdornment={
              <IconButton Icon={<VIcon name="copy" />} onClick={copySecretApiKeyToClipboard} />
            }
          />
          <TitledSwitch
            titleVariant="body1"
            name="secondaryMarketEnable"
            onChange={handleInputChange}
            checked={formState.secondaryMarketEnable.value}
            title="Activar Venti Swap"
          />
          <TitledSwitch
            titleVariant="body1"
            name="secondaryMarketEventButtonEnable"
            onChange={handleInputChange}
            checked={formState.secondaryMarketEventButtonEnable.value}
            title="Activar el botón de Venti Swap en el evento"
          />
          {/* <TitledSwitch
          titleVariant="body1"
          name="shouldBlacklistSellers"
          onChange={handleInputChange}
          checked={formState.shouldBlacklistSellers.value}
          title="Habilitar la blacklist de sellers"
        />
        <TitledSwitch
          titleVariant="body1"
          name="shouldExtendStopOfSalesToInvitations"
          onChange={handleInputChange}
          checked={formState.shouldExtendStopOfSalesToInvitations.value}
          title="Extender la fecha de corte de ventas hacia las invitaciones"
        />
        <TitledSwitch
          titleVariant="body1"
          name="shouldConvertPriceForUsd"
          onChange={handleInputChange}
          checked={formState.shouldConvertPriceForUsd.value}
          title="Convertir los precios a Dolares"
          description="Los precios de los tickets se mostrarán en dolares"
        />
        <TextInput
          title="Liberación de QRs"
          description="Cantidad de horas antes del evento para liberar los QRs"
          onChange={handleInputChange}
          name="hoursBeforeRelease"
          defaultValue={formState.hoursBeforeRelease.value}
          showHelpText={false}
          type={INPUT_TYPE.NUMBER}
        />
        <TextInput
          title="Finalización venta Venti Swap"
          description="Cantidad de horas antes del evento para finalizar la venta en Venti Swap"
          onChange={handleInputChange}
          name="hoursBeforeReleaseSecondaryMarket"
          defaultValue={formState.hoursBeforeReleaseSecondaryMarket.value}
          showHelpText={false}
          type={INPUT_TYPE.NUMBER}
        /> */}
          <SelectInput
            title="Modo de validación de QRs"
            description="Seleccione el modo de validación de QRs e indique el dispositivo que desea utilizar para marcar un QR como usado"
            onChange={handleInputChange}
            name="scanOptions"
            options={validationModeOptions}
            value={formState.scanOptions.value}
            showHelpText={false}
          />
          <Stack alignItems="center">
            <Button title="Guardar" fullwidth={false} onClick={updateEventSettings} />
          </Stack>
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(AdvancedEventSettingsPage);
